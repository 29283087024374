/*-- Storefront --*/
cx-storefront {
  max-width: 1440px !important;
  margin: 0 auto !important;
  max-height: 500px;
}

/*-- Header --*/
header .header {
  display: contents;
  background: none;
}

cx-page-slot.SiteLogo {
  width: auto;
}

/*-- Header: Product Search Catalog --*/
cx-page-layout.header cx-page-slot.SearchBox {
  display: contents;
  flex-wrap: wrap;
  justify-content: center;
}

/*-- Breadcrumb --*/
header .NavigationBar {
  min-height: auto;
  margin-top: 0.5em;
}

cx-page-slot.BottomHeaderSlot.has-components,
cx-page-slot.BottomHeader.has-components {
  padding: 0 1rem;
}
cx-page-slot.BottomHeaderSlot ol.breadcrumb {
  padding-right: 0;
  li a {
    display: inline-block;
    min-height: fit-content;
    margin-top: 0;
  }
}
cx-page-slot.SocialMediaLinkHeader>app-shared-link .social-icon,
cx-page-slot.NavigationBar div.cstm-menubar>p-menubarsub.p-element>ul.p-menubar-root-list>li.p-menuitem a.p-menuitem-link{
  margin-top: 0;
}
cx-page-slot.NavigationBar div.cstm-menubar>p-menubarsub.p-element>ul.p-menubar-root-list>li.p-menuitem a.p-menuitem-link {
  padding: 5px 0 !important;
  margin: 0 5px !important;
  border-bottom: 1px dashed transparent;
  &:hover{
    text-decoration: none !important;
    border-bottom: 1px dashed var(--secondary_text_color) !important;
  }
}
cx-page-slot.NavigationBar .cstm-menubar.p-menubar .p-menuitem.p-highlight > .p-menuitem-content{
  text-decoration: none !important;
}

/*-- Home Page --*/
cx-page-layout.LandingPage2Template {
  margin: 0;
  .mail-link-sgre {
    font-size: var(--tertiary_font_size);
    text-decoration: none;
    margin: 0;
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }
}

cx-page-layout.LandingPage2Template {
  max-width: 100%;
  padding: 0 15px 0 15px;
}

cx-page-layout.LandingPage2Template cx-page-slot.Section1 {
  margin: 0;
  max-width: 100%;
}

cx-page-layout.LandingPage2Template cx-page-slot.Section3 {
  max-width: var(--cx-page-width-max);
  padding: 0;
  display: contents;
}
cx-page-layout.LandingPage2Template cx-page-slot.Section5A,
cx-page-layout.LandingPage2Template cx-page-slot.Section5B {
  max-width: 50%;
  padding-top: 4%;
  display: flex;
}
cx-page-layout.LandingPage2Template cx-page-slot.Section6,
cx-page-layout.LandingPage2Template cx-page-slot.Section4,
cx-page-layout.LandingPage2Template cx-page-slot.Section5,
cx-page-layout.LandingPage2Template cx-page-slot.Section8 {
  flex-wrap: nowrap;
  padding-top: 4%;
  display: flex;
  align-items: center;
}
cx-page-slot.Section5B .anonymous-user-container ul li {
  display: list-item;
}
cx-page-slot.Section8 .anonymous-user-container a.font-weight-500 {
  text-decoration: none;
}
.font-weight-500{
  font-weight: 500;
  padding: 0 !important;
}
cx-page-layout.LandingPage2Template cx-page-slot.Section8 {
  align-items: center;
  .anonymous-user-container a:hover {
    text-decoration: none;
    opacity: 0.6;
  }
}

/*-- Purchase-terms-and-condition --*/
.ContentPage1Template > cx-page-slot.Section1.has-components {
  padding-left: 3%;
  padding-right: 3%;
}

/*-- Contact Us, FAQ, Terms of Use --*/
cx-page-layout.ContentPage1Template {
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex !important;
}

// For Privacy Policy page alignment
cx-page-layout.ContentPage1Template:has(cx-page-slot.Section1.has-components){
display: contents!important;
}

cx-page-slot.FooterSocialMediaLinks.has-components {
  display: flex;
  flex-wrap: nowrap;
}
cx-page-slot.FooterSocialMediaLinks {
  justify-content: flex-start;
  & > app-shared-link {
    flex: unset;
    .icon-cont {
      margin-left: 0 !important;
      margin-right: 1.5rem;
      & > a {
        margin-top: 0;
      }
    }
  }
}

cx-page-layout.ContentPage1Template cx-page-slot.Section2.has-components {
  padding: 0% 8% 0% 8%;
}

cx-page-layout.ContentPage1Template cx-page-slot.Section4.has-components {
  padding: 0 9% 0 9%;
}

cx-page-slot.Section2Borders,
cx-page-slot.Section2Bquotations,
cx-page-slot.Section2Bproducts,
cx-page-slot.Section2Baccessrequests {
  padding: 0 3%;
}

cx-page-layout.ContentPage1Template cx-page-slot.Section3 {
  margin-bottom: 2%;
}

cx-page-layout.ContentPage1Template cx-page-slot.Section2BaccessrequestsQ2 {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 50%;
}

cx-page-layout.ContentPage1Template cx-page-slot.Section2BaccessrequestsQ1 {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 50%;
}

/*-- PLP --*/
cx-page-layout.ProductGridPageTemplate {
  max-width: 100%;
  margin: 0;
}

.ProductGridPageTemplate .ProductLeftRefinementSlot {
  max-width: 26%;
}

/*-- PDP --*/
cx-page-layout.ProductDetailsPageTemplate {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "summaryA summaryB"
    "alternateProduct alternateProduct"
    "relatedProduct relatedProduct";
  padding: var(--cx-padding, 0 15px 0);
}

/*-- Users --*/
.BodyContent .TableContent {
  margin: 0;
  max-width: 100%;
}

cx-page-layout.ProductDetailsPageTemplate > cx-page-slot.SummaryA {
  grid-area: summaryA;
  display: block;
}

cx-page-layout.ProductDetailsPageTemplate > cx-page-slot.SummaryB {
  padding: 15px;
  background: var(--secondary_background) !important;
  grid-area: summaryB;
  display: block;
  height: fit-content;
  min-height: 100%;
}

cx-page-layout.ProductDetailsPageTemplate > cx-page-slot.AlternateProduct {
  grid-area: alternateProduct;
  flex-wrap: nowrap;
}

cx-page-layout.ProductDetailsPageTemplate > cx-page-slot.RelatedProduct {
  grid-area: relatedProduct;
}

/* -- Cart -- */
cx-page-layout.CartPageTemplate {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 15px !important;
}

cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 15px !important;
}

cx-page-layout.app-priced-entries-accordion {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 15px !important;
}

.CartPageTemplate .TableContent {
  margin: 0;
  max-width: 100%;
}

/*-- Saved Carts --*/
cx-page-layout.SavedCartsPageTemplate {
  margin: 0;
  max-width: 100%;
}

cx-page-layout.SavedCartsPageTemplate cx-page-slot {
  padding-top: 0;
}

cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.BodyContent {
  width: 100%;
  flex: 0 100% !important;
}

.SavedCartsPageTemplate .BodyContent {
  margin: 0;
  max-width: 100% !important;
}

.SavedCartsPageTemplate .SideContent {
  margin: 0;
  max-width: 100% !important;
}

/*-- Order History --*/
cx-page-layout.OrderHistoryPageTemplate {
  margin: 0;
  max-width: 100%;
  cx-page-slot.Tabs .card .card-header button{
    &:focus, &:active{
      box-shadow: none;
    }
  }
}

cx-page-layout.OrderHistoryPageTemplate cx-page-slot {
  padding-top: 0;
}

.OrderHistoryPageTemplate .TopContent {
  margin: 0;
  max-width: 100% !important;
}

.OrderHistoryPageTemplate .BodyContent {
  margin: 0;
  max-width: 100% !important;
}

.OrderHistoryPageTemplate .BottomContent {
  margin: 0;
  max-width: 100% !important;
}

/*-- FAQ --*/
// cx-page-layout .Section2Baccessrequests,
// cx-page-layout .Section2Bquotations,
// cx-page-layout .Section2Borders,
// cx-page-layout .Section2Bproducts {
//     width: 75%;
//     max-width: 75%;
// }
cx-page-layout .Section2Aaccessrequests,
cx-page-layout .Section2Aquotations,
cx-page-layout .Section2Aorders,
cx-page-layout .Section2Aproducts {
  // width: 25%;
  max-width: 25%;
}

/*-- My Profile --*/
cx-page-layout.AccountPageTemplate cx-page-slot {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

/*-- Invite User --*/
cx-page-layout .InviteUserPageTemplate {
  padding: 3%;
}

cx-page-layout .InviteUserPageTemplate cx-page-slot.Section3 {
  max-width: 100% !important;
}

/*-- Footer --*/
cx-scroll-to-top button {
  background-color: var(--primary_btn_background);
}

cx-scroll-to-top button:hover {
  background-color: var(--primary_btn_hover_background);
}

cx-page-layout.footer {
  // display: flex;
  // flex-wrap: wrap;
  background-color: unset;
  color: unset;
}

cx-page-slot.FooterImage {
  height: 100%;
}

cx-scroll-to-top button {
  background-color: var(--primary_btn_background);
}

cx-scroll-to-top button:hover {
  background-color: var(--primary_btn_hover_background);
}

cx-page-layout.AccountPageTemplate > cx-page-slot.TopContentA {
  padding: 14px;
  grid-area: TopContentA;
  background: var(--secondary_background) !important;
  margin-right: 15px;
}

cx-page-layout.AccountPageTemplate > cx-page-slot.TopContentB {
  padding: 14px;
  grid-area: TopContentB;
}

cx-page-layout.AccountPageTemplate > cx-page-slot.MiddleContent {
  grid-area: MiddleContent;
  flex-wrap: nowrap;
  margin: 0;
  max-width: 100%;
}

cx-page-layout.AccountPageTemplate > cx-page-slot.BottomContentOrderDetails {
  grid-area: BottomContent;
}

cx-page-layout.AccountPageTemplate {
  display: grid;
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "TopContentA TopContentB "
    "MiddleContent MiddleContent  "
    "BottomContent  BottomContent ";
  padding: var(--cx-padding, 0 25px 0);
  // display: grid;
  // grid-column-gap: 20px;
  // grid-row-gap: 0px;
  // grid-template-columns: 1fr 1fr;
  // grid-template-rows: repeat(1, 0) 2fr;
}

cx-page-layout.AccountPageTemplate cx-page-slot.BottomContentMyProfile app-shared-paragraph > div > div{
  margin: 0 !important;
  padding: 0.5rem !important;
}
cx-page-layout.UsersPageTemplate cx-page-slot.BodyContent app-shared-paragraph app-profile-paragraph > div.p-para > div > div{
  margin: 0 !important;
  &>.col-md-3{
    padding-left: 0 !important;
  }
}

cx-page-layout.AccountPageTemplate cx-page-slot {
  margin: 0px;
  padding-top: 1rem;
}

cx-page-layout.MiddleContent {
  margin: 0;
  max-width: 100%;
}

/*-- Footer --*/
cx-page-layout.ErrorPageTemplate cx-page-slot.MiddleContent.has-components,
cx-page-layout.ErrorPageTemplate cx-page-slot.BottomContent.has-components {
  max-width: 100%;
}

cx-page-slot.FooterNoticeText.has-components app-privacy-policy-paragraph {
  display: flex;
}

/* Tables - Start */
cx-page-layout.CartPageTemplate {
  app-cart {
    width: 100%;
  }
}

cx-page-layout.MultiStepCheckoutSummaryPageTemplate,
cx-page-layout.CartPageTemplate,
cx-page-layout.AccountPageTemplate,
cx-page-layout.UsersPageTemplate,
cx-page-layout.OrderHistoryPageTemplate,
cx-page-layout.BulkOrderPageTemplate,
cx-page-layout.UsersPageTemplate,
cx-page-layout.OrderRequestsPageTemplate,
cx-page-layout.InviteUserPageTemplate,
cx-page-layout.SavedCartsPageTemplate {
  div.p-datatable-wrapper {
    overflow-x: auto;
  }
}
/* Tables - End */

/* Footer - Start */
cx-page-slot.FooterNavigation {
  .d-flex {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 3rem;

    & > div.ng-star-inserted {
      & > div {
        margin: 0 !important;
      }
    }
  }
  .footerLink .cstm-anchor-primary {
    min-height: fit-content;
    margin-bottom: 0.75rem;
  }
}
/* Footer - End */
/* Users */
cx-page-layout.UsersPageTemplate cx-page-slot {
  justify-content: flex-start !important;
}
