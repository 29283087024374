/* You can add global styles to this file, and also import other style files */
@import 'styles-config';
@import '@spartacus/styles/index';
@import 'primeicons/primeicons.css';
@import './styles/SGRE-custom-styles/media-queries.scss';
@import './styles/SGRE-custom-styles/OOTB.scss';
@import './styles/SGRE-custom-styles/primeng.scss';
@import './styles/SGRE-custom-styles/sg-icons.css';

@font-face {
  font-family: 'Weissenhof Grotesk W01 Regular,Weissenhof Grotesk W01,Verdana,Geneva,Tahoma,sans-serif';
  src: url('./assets/fonts/Weissenhof-Grotesk-W01-Regular.ttf');
}

:root {
  --primary_font_family: "Weissenhof Grotesk W01 Regular,Weissenhof Grotesk W01,Verdana,Geneva,Tahoma,sans-serif";
  --primary_font_size: 15px;
  --primary_background: #F5F6F8;
  --primary_color: #321850;
  --primary_text_color: #5A4673;
  --primary_border: 1px solid var(--primary_color);
  --secondary_font_size: 15px;
  --secondary_background: #E8E8E8;
  --secondary_text_color: #3C3C3C;
  --tertiary_background: #F9FAFF;
  --tertiary_text_color: #2F2F2F;
  --tertiary_font_size: 14px;
  /* buttons */
  --primary_btn_text: #FFF;
  --primary_btn_background: #321850;
  --primary_btn_hover_background: #847495;
  --primary_btn_box_shadow: 0 0 0 0.2rem rgba(50, 24, 80, 0.25);
  --secondary_btn_background: #E8E8E8;
  --secondary_btn_border: #D6E4F3;
  --secondary_btn_text: #5E6981;
  --secondary_btn_box_shadow_color: #5E6981;
  --secondary_btn_active_background: #D9E5F2;
  /* anchor tag */
  --primary_anchor_hover: #ADA3B9;
  /* Primeng */
  --PNG_border: 1px solid #D1D1D1;
  --PNG_border_color: #6F35B2;
  --PNG_border_focus: #0052C0;
  --PNG_readonly_color: #E0E0E0;
  --PNG_form_text: #434B5B;
  --PNG_hover_background: #D1D1D1;
  /* specific-components */
  --PNG_inputSwitch_before_bg: #ECF2F8;
  --PNG_inputSwitch_before_head: #5C85B0;
  --PNG_inputSwitch_after_bg: #77ABF1;
  --PNG_inputSwitch_after_head: #321850;
  /* subsidiary colors */
  --subsidiary_color1: #4298B5;
  --subsidiary_color2: #005200;
  --subsidiary_color3: #2F80ED;
  --subsidiary_color4: #ABCAE9;
  --subsidiary_color5: #1A5EAD;
  /* colors */
  --white: #FFF;
  --black: #000;
  --grey: #CFCFCF;
  --light_grey: #F4F4F4;
  --dark_grey: #9097A8;
}
body {
  margin: 0;
  font-family: var(--primary_font_family) !important;
  font-size: var(--primary_font_size);
  color: var(--secondary_text_color);
  letter-spacing: 0;
}
a {
  font-size: .85em;
  cursor: pointer;

  &:focus {
    outline-width: 0;
  }
}
/*-------------------- COMMON STYLES --------------------*/
.bg-clr-lght-grey {
  background-color: var(--light_grey);
}
.cstm-bttm-border {
  border-bottom: 1px solid #e8e8e8;
}
.secondary-anchor {
  color: var(--secondary_text_color);
}
.secondary-anchor:hover {
  color: var(--secondary_text_color);
  border-bottom: 1px dashed var(--secondary_text_color) !important;
}
.custom-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-align-top {
  display: flex;
  align-items: flex-start;
}
.custom-align-center {
  display: flex;
  align-items: center;
}
.custom-justify-start {
  display: flex !important;
  justify-content: start !important;
}
.custom-justify-center {
  display: flex;
  justify-content: center;
}
.custom-justify-end {
  display: flex !important;
  justify-content: end !important;
}
.custom-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.custom-flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}
.custom-mt-4 {
  margin-top: 2rem !important;
}
.custom-mb-4 {
  margin-bottom: 2rem !important;
}
.list-unstyled {
  list-style: none;
}
.word-break-all {
  word-break: break-all;
}
.fa-copy,
.fa-trash {
  color: var(--primary_btn_background)
}

/*-------------------- COMMON COMPONENTS --------------------*/
/*-- Primary Anchor --*/
.cstm-anchor-primary {
  background-color: transparent;
  color: var(--primary_text_color);
  font-weight: 400;
}
.cstm-anchor-primary:hover {
  color: var(--primary_anchor_hover);
  text-decoration: none;
}

/*-- Primary Button --*/
.cstm-btn-primary {
  min-height: 38px;
  min-width: 80px;
  height: calc(1.5em + .75rem + 2px);
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0.5rem 1rem !important;
  border: 1px solid var(--primary_btn_background);
  background-color: var(--primary_btn_background) !important;
  color: var(--white) !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  line-height: 1 !important;
}
.cstm-btn-primary:hover {
  color: var(--white) !important;
  border: 1px solid var(--primary_btn_hover_background) !important;
  background-color: var(--primary_btn_hover_background) !important;
}
.cstm-btn-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(81, 59, 106, .5) !important;
}
.cstm-btn-primary:not(:disabled):not(.disabled):active,
.cstm-btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--primary_btn_background) !important;
  border: 0 !important;
  filter: none;
}
.cstm-btn-primary:disabled {
  background-color: #878787 !important;
  color: white;
  font-size: var(--tertiary_font_size);
  font-weight: 100;
}

/*-- Light Button --*/
.cstm-btn-light {
  min-height: 38px;
  min-width: 80px;
  height: calc(1.5em + .75rem + 2px);
  border-radius: 0 !important;
  padding: 0.5rem 1rem !important;
  border: 1px solid var(--primary_btn_background) !important;
  background-color: var(--white) !important;
  color: var(--primary_color) !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  line-height: 1 !important;
}
.cstm-btn-light:hover {
  color: var(--primary_btn_hover_background) !important;
  border: 1px solid var(--primary_btn_hover_background) !important;
  background-color: var(--white) !important;
}
.cstm-btn-light:focus {
  box-shadow: 0 0 0 .2rem rgba(50, 24, 80, .5) !important;
}
.cstm-btn-light:not(:disabled):not(.disabled):active,
.cstm-btn-light:not(:disabled):not(.disabled).active {
  background-color: var(--primary_btn_background) !important;
  border: 0 !important;
  filter: none;
}
.cstm-btn-light:disabled {
  background-color: #878787 !important;
  color: white;
  font-size: var(--tertiary_font_size);
  font-weight: 100;
}

// power-bi
html,
body {
  height: 100%
}
.reportClass {
  height: 100% !important;
}
powerbi-report {
  height: 100% !important;
}
iframe {
  border: none;
}
.reportContainer {
  height: 100% !important;
}
@media only screen and (max-width: 1000px) {
  header.is-expanded .navigation {
    height: 20vh;
  }
}
.alert .close {
  right: 25px;
}
.custom-select{
  font-size: var(--primary_font_size) !important;
  border-radius: 0 !important;
  &:focus, &:active{
    box-shadow: none !important;
  }
}
body .form-control{
  border-radius: 0 !important;
  &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active{
    border-color: var(--PNG_border_color) !important;
    box-shadow: var(--primary_btn_box_shadow) !important;
    background-color: var(--white) !important;
  }
  &:not(:disabled):not(.disabled):hover{
    border-color: #d1d5db !important;
  }
}
.chooseAFile-icon {
  font-size: 2.6rem !important;
  color: #d1d1d1;
  &:hover {
    color: #3c3c3c !important;
  }
}
.select-file-label {
  line-height: 1 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.card:has(div.collapse) .card-header .toggling-arrow-icon {
  transform: rotate(180deg);
}
.card:has(div.collapse.show) .card-header .toggling-arrow-icon {
  transform: rotate(0deg);
}
#accordionExample.accordion .card-header {
  background-color: transparent;
  padding: 0.9rem 1.2rem 0.9rem 0;
}

.custom-control-input:not(:checked) ~ .checkout-checkbox-label:before {
  background-color: var(--white) !important;
}
.checkout-checkbox-label:before {
  border-radius: 0 !important;
}
.custom-control-input:checked ~ .checkout-checkbox-label:before {
  border-color: var(--primary_color) !important;
  background-color: var(--primary_color) !important;
}
.checkout-checkbox-label:before, .checkout-checkbox-label:after {
  top: 0.1rem !important;
}
.custom-control-input:focus ~ .checkout-checkbox-label:before {
  border-color: var(--PNG_border_color) !important;
  box-shadow: var(--primary_btn_box_shadow) !important;
}