body {
  .p-component {
    @font-face {
      font-family: var(--primary_font_family) !important;
      src: url("https://db.onlinewebfonts.com/t/0bf5b3831b9c3bde8cad5c7d9ae9f3d2.eot");
      src: url("https://db.onlinewebfonts.com/t/0bf5b3831b9c3bde8cad5c7d9ae9f3d2.eot?#iefix") format("embedded-opentype"),
        url("https://db.onlinewebfonts.com/t/0bf5b3831b9c3bde8cad5c7d9ae9f3d2.woff2") format("woff2"),
        url("https://db.onlinewebfonts.com/t/0bf5b3831b9c3bde8cad5c7d9ae9f3d2.woff") format("woff"),
        url("https://db.onlinewebfonts.com/t/0bf5b3831b9c3bde8cad5c7d9ae9f3d2.ttf") format("truetype"),
        url("https://db.onlinewebfonts.com/t/0bf5b3831b9c3bde8cad5c7d9ae9f3d2.svg#Weissenhof Grotesk W01 Regular") format("svg");
    }

    font-family: var(--primary_font_family) !important;
    letter-spacing: 0 !important;
    font-size: var(--primary_font_size);

    .p-inputtext {
      font-family: var(--primary_font_family) !important;
      letter-spacing: 0 !important;
    }
  }

  font-family: var(--primary_font_family) !important;
  letter-spacing: 0 !important;
}

/*-- Header Search Textbox START --*/
.header-searchText.p-autocomplete.p-component.p-inputwrapper {
  width: 100%;
}
.header-searchText .p-autocomplete-input.p-inputtext.p-component {
  border-radius: 0;
  background: var(--secondary_background);
  color: var(--primary_font_size);
}
.header-searchBtn.p-button {
  background: var(--grey);
}
/*-- Header Search Textbox END --*/

/*-- Login Banner START --*/
.p-messages.p-component.login-banner>.p-message {
  margin: 0.2em 0 0 0;
}
/*-- Login Banner END --*/

/*-- Custom Breadcrumb START --*/
.cstm-breadcrumb a.p-menuitem-link.p-menuitem-link-active {
  outline: 0 none !important;
  outline-offset: 0px;
  box-shadow: none !important;
}
nav.cstm-breadcrumb.p-breadcrumb.p-component {
  border: 0;
  padding: 1rem 0;
}
.p-breadcrumb .p-breadcrumb-list li {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.cstm-breadcrumb.p-breadcrumb li.p-menuitem-separator.dNone {
  display: none;
}
/*-- Custom Breadcrumb END --*/

/*-- Custom Dropdown START --*/
.cstm-dropdown.p-dropdown {
  width: 100%;
  margin: 0;
  border: var(--PNG_border);
  border-radius: 0;
}
.cstm-dropdown.p-dropdown .p-inputtext {
  color: var(--secondary_text_color);
  padding: 0.5rem 1rem;
  font-size: var(--primary_font_size);
}
.cstm-dropdown.p-dropdown .p-dropdown-trigger {
  width: 1rem;
  padding: 0.5rem 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--secondary_text_color);
}
.cstm-dropdown.p-dropdown span.p-dropdown-trigger-icon.fa-solid.fa-caret-down::before {
  content: none;
}
.cstm-dropdown.p-dropdown span.p-dropdown-trigger-icon.fa-solid.fa-caret-down {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
// .cstm-dropdown.p-dropdown .p-dropdown-items-wrapper {
//     margin-top: .3rem;
// }
.cstm-dropdown.p-dropdown .p-dropdown-items-wrapper .p-dropdown-items {
  padding: 0;
}
.cstm-dropdown.p-dropdown .p-dropdown-items-wrapper ul {
  margin-bottom: 0;
}
.cstm-dropdown.p-dropdown .p-dropdown-panel {
  border: 1px solid var(--grey);
  border-radius: 0;
}
.cstm-dropdown.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.cstm-dropdown.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  padding: .5rem 1.5rem;
  // border-bottom: 1px solid var(--grey);
  // line-height: 50px;
  color: var(--secondary_text_color);
}
.cstm-dropdown.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: var(--white);
}
.cstm-dropdown.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.cstm-dropdown.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: var(--primary_btn_background);
  color: var(--white);
}
.cstm-dropdown.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--grey);
}
.cstm-dropdown.p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: var(--PNG_hover_background);
}
.cstm-dropdown.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--PNG_border_color);
  box-shadow: var(--primary_btn_box_shadow);
}
.cstm-dropdown.p-dropdown:focus,
.cstm-dropdown .p-dropdown-label:focus {
  outline: 0 !important;
}
.cstm-dropdown.p-dropdown.p-disabled {
  background: var(--secondary_btn_background);
}
/*-- Custom Dropdown END --*/

/*-- Custom Pagination START --*/
.cstm-pagination.p-paginator .p-paginator-first,
.cstm-pagination.p-paginator .p-paginator-prev,
.cstm-pagination.p-paginator .p-paginator-next,
.cstm-pagination.p-paginator .p-paginator-last {
  height: 1.5rem;
  min-width: 2rem;
}
.cstm-pagination.p-paginator .p-paginator-pages .p-paginator-page {
  height: 2rem;
  min-width: 2rem;
}
.cstm-pagination.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-radius: 0.25rem;
  background: var(--primary_color);
  border-color: var(--primary_color);
  color: var(--white);
}
/*-- Custom Pagination END --*/
 
/*-- Custom Radio Button START --*/
.custom-radioBtn.p-radiobutton {
  margin-right: 0.7em;
}
.custom-radioBtn.p-radiobutton .p-radiobutton-box,
.custom-radioBtn.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--primary_btn_background);
}
.custom-radioBtn.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--primary_btn_background);
    background: transparent;
}
.custom-radioBtnp-radiobutton p-component p-radiobutton-focused p-radiobutton-checked,
.custom-radioBtnp-radiobutton-box p-component p-highlight p-focus,
.custom-radioBtn.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 .2rem rgba(81, 59, 106, .5) !important;
}
.custom-radioBtn.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    border-color: var(--primary_btn_background);
    background: var(--primary_btn_background);
}
/*-- Custom Radio Button END --*/
 
/*-- Table Styles START --*/
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--white);
}
.p-datatable .p-datatable-thead>tr>th {
  padding: 0.7rem;
}
.p-datatable .p-datatable-tbody>tr>td {
  border: 1px solid var(--black);
  padding: 0.7rem;
  text-align: left;
  font-size: 13.5px;
  color: #3c3c3c;
}
.p-datatable .p-datatable-tbody>tr>td>.align-right {
  display: flex;
  justify-content: end;
}
.p-paginator {
  display: flex;
  justify-content: flex-end;
}
.p-datatable .p-paginator-bottom {
  border-width: 0;
}
p-multiselect .p-multiselect {
  outline: none;
  border-radius: 0;
  &:hover {
    border-color: #d1d5db !important;
  }
}
.p-multiselect:not(.p-disabled).p-focus {
  border-color: var(--PNG_border_color);
  box-shadow: var(--primary_btn_box_shadow);
}
.p-multiselect-filter.p-inputtext,
.p-dropdown-filter.p-inputtext {
  outline: none;
  border-radius: 0;

  &:active,
  &:focus {
    border-color: var(--PNG_border_color);
    box-shadow: var(--primary_btn_box_shadow);
  }
  &:hover {
    border-color: #d1d5db !important;
  }
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #d1d5db !important;
}
/*-- Table Styles END --*/

/*-- Custom AutoComplete START --*/
.cstm-autocomplete.p-autocomplete {
  height: 100% !important;
  width: 100% !important;
}
.cstm-autocomplete.p-autocomplete .p-autocomplete-input,
.cstm-autocomplete.p-autocomplete .p-autocomplete-input:focus {
  width: 100% !important;
}
.cstm-autocomplete.p-autocomplete .p-autocomplete-input {
  background-color: var(--secondary_btn_background);
  border-color: var(--secondary_btn_background);
  border-radius: 0 !important;
}
.cstm-autocomplete.p-autocomplete .p-autocomplete-input:focus {
  background-color: var(--secondary_btn_background) !important;
  border-color: var(--secondary_btn_background) !important;
  outline: 0;
  box-shadow: none;
  width: 100% !important;
}
.cstm-autocomplete .p-autocomplete-panel {
  border-radius: 0;
  background: var(--secondary_btn_background);
  color: var(--secondary_text_color);
}
.cstm-autocomplete .p-autocomplete-panel ul.p-autocomplete-items {
  margin: 0;
}
.cstm-autocomplete .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group:first-child {
  margin: 0 0 0.2em 0;
  padding: 0 1.25rem 0.25rem 1.2rem;
}
.cstm-autocomplete .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0.2em 0 0.2em 0;
  padding: 0.25rem 1.2rem;
  color: var(--secondary_text_color);
  background: var(--secondary_btn_background);
  font-weight: bold;
  font-size: 1rem;
}
.cstm-autocomplete .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.25em 1.25em;
  color: var(--secondary_text_color);
  background: var(--secondary_btn_background);
  font-weight: 500;
  font-size: 0.85em;
}
.cstm-autocomplete .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  background: var(--grey);
}
/*-- Custom AutoComplete END --*/

/*-- OrderHistory AutoComplete START --*/
.orderHistory-autocomplete.p-autocomplete {
  height: 46px;
  width: 100% !important;
}
// .orderHistory-autocomplete.p-autocomplete .p-autocomplete-input,
// .orderHistory-autocomplete.p-autocomplete .p-autocomplete-input:focus {
//     width: 100% !important;
// }
.orderHistory-autocomplete.p-autocomplete .p-autocomplete-input {
  width: 100%;
  background-color: var(--white);
  border-color: var(--secondary_btn_background);
  border-radius: 0 !important;
  font-size: var(--secondary_font_size);
}
.orderHistory-autocomplete.p-autocomplete .p-autocomplete-input:focus {
  width: 100% !important;
  outline: 0;
  background-color: var(--white) !important;
  border-color: var(--PNG_border_color) !important;
  color: var(--dark_grey) !important;
  box-shadow: var(--primary_btn_box_shadow);
}
/*-- OrderHistory AutoComplete END --*/

/*-- Custom Menubar START --*/
p-menubar.p-element {
  display: flex;
  justify-content: end;
}
.cstm-menubar.p-menubar.p-component {
  padding: 0;
  margin: 0;
  border: 0;
  background: var(--white);
}
.cstm-menubar.p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link {
  color: var(--secondary_text_color);
  padding: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.cstm-menubar.p-menubar .p-menuitem {
  background-color: white;
}
.cstm-menubar.p-menubar.p-component>p-menubarsub.p-element>ul {
  margin-bottom: 0;
}
.cstm-menubar.p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link {
  font-size: var(--primary_font_size);
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list {
  display: block;
  width: 10rem;
  border: 1px black;
  padding: 0;
}

cx-page-slot.NavigationBar .cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active {
  &>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }
  &>p-menubarsub>.p-submenu-list {
    display: none;
  }
  &:hover>p-menubarsub>.p-submenu-list {
    display: block;
  }
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list>.p-menuitem:hover {
  display: block;
  border-bottom: 1px solid var(--grey);
  background-color: #f2f3f5;
  padding: 10px;
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list>.p-menuitem {
  display: block;
  border-bottom: 1px solid var(--grey);
  background-color: var(--light_grey);
  padding: 10px;
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list>.p-menuitem:last-child {
  border-bottom: 0;
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list>.p-menuitem:hover {
  background: var(--primary_btn_background);
  color: var(--white);
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list>.p-menuitem:hover {
  background: var(--primary_btn_background);
  color: var(--white);
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>.p-menubarsub>.p-submenu-list>.p-menuitem-content .p-menuitem-link :hover {
  background: var(--primary_btn_background);
  color: var(--white);
  text-decoration: none;
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>.p-menubarsub>.p-submenu-list>.p-menuitem {
  display: block;
  border: 1px solid black;
  background-color: #f2f3f5;
  padding: 10px;
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>.p-menubarsub>.p-submenu-list>.p-menuitem:hover {
  background: var(--primary_btn_background);
  color: var(--white);
  text-decoration: none;
}
.cstm-menubar.p-menubar .p-menuitem.p-highlight>.p-menuitem-content {
  background: none;
  text-decoration: underline;
}
.cstm-menubar.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
  background-color: var(--primary_btn_background);
  color: var(--white);
}
.cstm-menubar.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
  color: var(--secondary_text_color);
  background: none;
}
.cstm-menubar.p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary_text_color);
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list>.p-menuitem:hover .p-menuitem-text {
  color: var(--white);
}
.cstm-menubar.p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
  background-color: white !important;
}
.cstm-menubar.p-menubar.p-component>p-menubarsub.p-element>ul>li {
  background-color: white !important;
  font-size: 0.85em !important;
}
/*-- Custom Menubar END --*/

/* Checkout START */
.cstm-accordion.p-accordion .p-accordion-header>.p-accordion-header-link {
  font-size: var(--secondary_font_size);
  color: var(--secondary_text_color);
}
.shipping-details-container {
  .p-accordion-header-link,
  .p-toggleable-content .p-accordion-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.cstm-accordion.p-accordion .p-accordion-header>.p-accordion-header-link {
  font-size: var(--secondary_font_size);
  color: var(--secondary_text_color);
}
/* Checkout END */

/* Invite User - Start */
.inviteUser-table-container .checkbox-cell {
  text-align: center !important;
}
/* Invite User - End */

/* My Profile - Start */
.myProfile-table-container .checkbox-cell {
  text-align: center !important;
}
/* My Profile - End */
.border-red-500 {
  border-color: red !important;
}