%cx-tab {
  // Tabs
  --cx-tab-gap: 0;
  --cx-tab-btn-bg-color: var(--cx-color-background);
  --cx-tab-btn-width: 20%;
  --cx-tab-btn-border: none;
  --cx-tab-btn-border-radius: none;
  --cx-tab-btn-font-size: 1.2rem;
  // Tab panel
  --cx-tab-panel-bg: var(--cx-color-background);
  --cx-tab-panel-padding: 50px 0;
  --cx-tab-panel-border-radius: 0;
  --cx-tab-panel-margin-top: 0;
  --cx-tab-panel-border-top: 1px var(--cx-color-light) solid;
  --cx-tab-panel-border-end: none;
  --cx-tab-panel-border-bottom: none;
  --cx-tab-panel-border-start: none;

  .tab-btn {
    order: 0;
    transition: all 0.6s;
    font-size: var(--cx-tab-btn-font-size);
    text-align: center;
    margin: 0;
    border: 0;

    &:first-of-type {
      grid-column: 3;
    }

    margin-bottom: 0;

    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
    }

    &.active,
    &:hover:not([disabled]) {
      color: var(--cx-color-primary);
    }
    &:focus {
      z-index: 1;
    }
    @include forFeature('a11yCroppedFocusRing') {
      &:focus-visible {
        outline-offset: -3px;
      }
    }

    &.active:after,
    &:hover:not([disabled]):after {
      width: 100%;
    }
    &.active:after,
    &.active:hover:after {
      opacity: 1;
    }
    &:not(.active):hover:after {
      opacity: 0.5;
    }

    // border effect
    &:after {
      content: '';
      display: block;
      margin-top: 15px;
      margin-inline-end: auto;
      margin-bottom: auto;
      margin-inline-start: auto;
      height: 5px;
      background: var(--cx-color-primary);

      // the tab hover effect uses a border in the :after pseudo
      // that is animated from 0% to 100% width
      width: 0;
      opacity: 0;
      transition: all 0.6s;
    }
  }

  div[role='tabpanel'] {
    margin-top: var(--cx-tab-panel-margin-top);
    border-top: var(--cx-tab-panel-border-top);
    border-inline-end: var(--cx-tab-panel-border-end);
    border-bottom: var(--cx-tab-panel-border-bottom);
    border-inline-start: var(--cx-tab-panel-border-start);
    border-radius: var(--cx-tab-panel-border-radius);
    padding: var(--cx-tab-panel-padding);
    background: var(--cx-tab-panel-bg);
    @include forFeature('a11yCroppedFocusRing') {
      &.active:focus-visible {
        outline-offset: -3px;
      }
    }
  }

  .tab {
    display: flex;
    gap: var(--cx-tab-gap);

    button {
      width: var(--cx-tab-btn-width);
      background-color: var(--cx-tab-btn-bg-color);
      border: var(--cx-tab-btn-border);
      border-radius: var(--cx-tab-btn-border-radius);
    }
  }

  .accordian {
    .tab-btn {
      width: 100%;
      border-top: 1px var(--cx-color-light) solid;
      font-size: 18px;
      padding-top: 15px;
      padding-inline-end: 0;
      padding-bottom: 0;
      padding-inline-start: 4%;
      text-align: start;
      height: 63px;
      position: relative;

      &:before {
        margin: 0px 15px;
        float: right;
        font-size: 33px;
        bottom: 12px;
        position: relative;
        color: var(--cx-color-text);
      }
      &:after {
        margin-top: 15px;
        margin-inline-end: auto;
        margin-bottom: auto;
        margin-inline-start: -4%;
      }
      &.active:after,
      &:hover:after {
        width: 104%;
      }
    }

    .tab-icon {
      margin: 0px 15px;
      float: right;
      font-size: 33px;
      bottom: 12px;
      position: relative;
      color: var(--cx-color-text);
    }

    div {
      padding: 15px 5px;
    }
  }
}
