﻿@font-face {
    font-family: 'SG-Icon-Font';
    src: url('../../assets/fonts/SG-Icon-Font/SG-Icon-Font.ttf') format('truetype'), url('../../assets/fonts/SG-Icon-Font/SG-Icon-Font.woff') format('woff'), url('../../assets/fonts/SG-Icon-Font/SG-Icon-Font.svg#SG-Icon-Font') format('svg');
    font-weight: normal;
    font-style: normal;
}

.sg-icon {
    display: inline-block;
    font: normal normal normal 14px/1 'SG-Icon-Font';
    font-size: 14px;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sg-icon.sg-icon-tools:before {
    content: "\E907"
}


.sg-icon-location_blade:before {
    content: "\E961";
}

.sg-icon-location_factory:before {
    content: "\E962";
}

.sg-icon-location_pin:before {
    content: "\E963";
}

.sg-icon-location_service:before {
    content: "\E964";
}

.sg-icon-location_store:before {
    content: "\E965";
}

.sg-icon-collapse:before {
    content: "\E966";
}

.sg-icon-country:before {
    content: "\E916";
}

.sg-icon-special_features:before {
    content: "\E95F";
}

.sg-icon-download_down:before {
    content: "\E953";
}

.sg-icon-download_up:before {
    content: "\E953";
}

.sg-icon-expand:before {
    content: "\E955";
}

.sg-icon-location:before {
    content: "\E955";
}

.sg-icon-output:before {
    content: "\E957";
}

.sg-icon-partner:before {
    content: "\E958";
}

.sg-icon-pause_video:before {
    content: "\E959";
}

.sg-icon-play_video:before {
    content: "\E95A";
}

.sg-icon-scope:before {
    content: "\E95B";
}

.sg-icon-sound_off:before {
    content: "\E95C";
}

.sg-icon-sound_on:before {
    content: "\E95D";
}

.sg-icon-year:before {
    content: "\E95E";
}

.sg-icon-customer_benefit:before {
    content: "\E93E";
}

.sg-icon-experience_since:before {
    content: "\E940";
}

.sg-icon-fleet_availabilty:before {
    content: "\E908";
}

.sg-icon-issues_resolved:before {
    content: "\E92B";
}

.sg-icon-life_time:before {
    content: "\E944";
}

.sg-icon-production_increase:before {
    content: "\E94F";
}

.sg-icon-serial_production:before {
    content: "\E950";
}

.sg-icon-service_category:before {
    content: "\E951";
}

.sg-icon-weather_uptime:before {
    content: "\E951";
}

.sg-icon-backlog:before {
    content: "\E923";
}

.sg-icon-blade:before {
    content: "\E924";
}

.sg-icon-client:before {
    content: "\E925";
}

.sg-icon-commonfile:before {
    content: "\E926";
}

.sg-icon-commonphone:before {
    content: "\E927";
}

.sg-icon-communications:before {
    content: "\E927";
}

.sg-icon-community:before {
    content: "\E929";
}

.sg-icon-compliance:before {
    content: "\E92A";
}

.sg-icon-conference:before {
    content: "\E92B";
}

.sg-icon-construction:before {
    content: "\E92C";
}

.sg-icon-efficiency:before {
    content: "\E92D";
}

.sg-icon-employees:before {
    content: "\E92E";
}

.sg-icon-energy:before {
    content: "\E92F";
}

.sg-icon-grid:before {
    content: "\E930";
}

.sg-icon-gwinstalled:before {
    content: "\E931";
}

.sg-icon-hseq:before {
    content: "\E932";
}

.sg-icon-humanresources:before {
    content: "\E933";
}

.sg-icon-internet:before {
    content: "\E934";
}

.sg-icon-it:before {
    content: "\E935";
}

.sg-icon-itapplication:before {
    content: "\E936";
}

.sg-icon-lightbulb:before {
    content: "\E937";
}

.sg-icon-manufacturing:before {
    content: "\E938";
}

.sg-icon-money:before {
    content: "\E939";
}

.sg-icon-nacelle:before {
    content: "\E93A";
}

.sg-icon-noise_emissions:before {
    content: "\E93B";
}

.sg-icon-nominal_power2:before {
    content: "\E93C";
}

.sg-icon-pm:before {
    content: "\E93D";
}

.sg-icon-procurement:before {
    content: "\E93E";
}

.sg-icon-profit:before {
    content: "\E93F";
}

.sg-icon-quality:before {
    content: "\E940";
}

.sg-icon-realtime:before {
    content: "\E941";
}

.sg-icon-revenue:before {
    content: "\E942";
}

.sg-icon-sales:before {
    content: "\E943";
}

.sg-icon-scale:before {
    content: "\E944";
}

.sg-icon-securefile:before {
    content: "\E945";
}

.sg-icon-supplychain:before {
    content: "\E946";
}

.sg-icon-sustainability:before {
    content: "\E947";
}

.sg-icon-tower:before {
    content: "\E948";
}

.sg-icon-training:before {
    content: "\E949";
}

.sg-icon-handshake:before {
    content: "\E94A";
}

.sg-icon-diploma:before {
    content: "\E94B";
}

.sg-icon-windfarm:before {
    content: "\E94C";
}

.sg-icon-imac:before {
    content: "\E94D";
}

.sg-icon-chart-up:before {
    content: "\E94E";
}

.sg-icon-magnifier:before {
    content: "\E94F";
}

.sg-icon-appaccess:before {
    content: "\E922";
}

.sg-icon-shareprice_down:before {
    content: "\E922";
}

.sg-icon-shareprice_up:before {
    content: "\E921";
}

.sg-icon-swipen_mobile:before {
    content: "\E900";
}

.sg-icon-youtube:before {
    content: "\E901";
}

.sg-icon-twitter:before {
    content: "\E902";
}

.sg-icon-linkedin:before {
    content: "\E903";
}

.sg-icon-instagram:before {
    content: "\E904";
}

.sg-icon-facebook:before {
    content: "\E905";
}

.sg-icon-windenergy:before {
    content: "\E906";
}

.sg-icon-service:before {
    content: "\E907";
}

.sg-icon-onshore:before {
    content: "\E908";
}

.sg-icon-offshore:before {
    content: "\E909";
}

.sg-icon-wind_class:before {
    content: "\E90A";
}

.sg-icon-technology:before {
    content: "\E90B";
}

.sg-icon-rotor_diameter:before {
    content: "\E90C";
}

.sg-icon-nominal_power:before {
    content: "\E90D";
}

.sg-icon-hub_high:before {
    content: "\E90E";
}

.sg-icon-available_certificate:before {
    content: "\E90F";
}

.sg-icon-telefonnummer:before {
    content: "\E910";
}

.sg-icon-sorting:before {
    content: "\E911";
}

.sg-icon-service_benefits:before {
    content: "\E912";
}

.sg-icon-search:before {
    content: "\E913";
}

.sg-icon-plus:before {
    content: "\E914";
}

.sg-icon-play:before {
    content: "\E915";
}

.sg-icon-pin:before {
    content: "\E916";
}

.sg-icon-exclamationMark:before {
    content: "\E917";
}

.sg-icon-download:before {
    content: "\E918";
}

.sg-icon-close:before {
    content: "\E919";
}

.sg-icon-check:before {
    content: "\E91A";
}

.sg-icon-calendar:before {
    content: "\E91B";
}

.sg-icon-burger:before {
    content: "\E91C";
}

.sg-icon-arrow:before {
    content: "\E91D";
}

.sg-icon-arrow_up_down:before {
    content: "\E91E";
}

.sg-icon-anschrift:before {
    content: "\E91F";
}

.sg-icon {
    position: relative;
    vertical-align: text-bottom;
}

.sg-icon:before {
    display: inline-block;
}

.sg-icon-arrow_left {
    font-size: 100%;
    top: -1px;
}

.sg-icon-arrow_left:before {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    content: "î¤";
}

.sg-icon-arrow_right {
    font-size: 75%;
    top: 0;
}

    .sg-icon-arrow_right:before {
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        content: "î¤ž";
    }

.sg-icon-location_blade {
    font-size: 125%;
}

.sg-icon-location_factory {
    font-size: 125%;
}

.sg-icon-location_pin {
    font-size: 125%;
}

.sg-icon-location_service {
    font-size: 125%;
}

.sg-icon-location_store {
    font-size: 125%;
}

.sg-icon-collapse {
    font-size: 125%;
}

.sg-icon-download_down {
    font-size: 125%;
}

.sg-icon-download_up {
    font-size: 125%;
}

.sg-icon-expand {
    font-size: 125%;
}

.sg-icon-location {
    font-size: 125%;
}

.sg-icon-output {
    font-size: 125%;
}

.sg-icon-partner {
    font-size: 125%;
}

.sg-icon-pause_video {
    font-size: 125%;
}

.sg-icon-play_video {
    font-size: 125%;
}

.sg-icon-scope {
    font-size: 125%;
}

.sg-icon-sound_off {
    font-size: 125%;
}

.sg-icon-sound_on {
    font-size: 125%;
}

.sg-icon-year {
    font-size: 125%;
}

.sg-icon-customer_benefit {
    font-size: 125%;
}

.sg-icon-experience_since {
    font-size: 125%;
}

.sg-icon-fleet_availabilty {
    font-size: 125%;
}

.sg-icon-issues_resolved {
    font-size: 125%;
}

.sg-icon-life_time {
    font-size: 125%;
}

.sg-icon-production_increase {
    font-size: 125%;
}

.sg-icon-serial_production {
    font-size: 125%;
}

.sg-icon-service_category {
    font-size: 125%;
}

.sg-icon-weather_uptime {
    font-size: 125%;
}

.sg-icon-backlog {
    font-size: 125%;
}

.sg-icon-blade {
    font-size: 125%;
}

.sg-icon-client {
    font-size: 125%;
}

.sg-icon-commonfile {
    font-size: 125%;
}

.sg-icon-commonphone {
    font-size: 125%;
}

.sg-icon-communications {
    font-size: 125%;
}

.sg-icon-community {
    font-size: 125%;
}

.sg-icon-compliance {
    font-size: 125%;
}

.sg-icon-conference {
    font-size: 125%;
}

.sg-icon-construction {
    font-size: 125%;
}

.sg-icon-efficiency {
    font-size: 125%;
}

.sg-icon-employees {
    font-size: 125%;
}

.sg-icon-energy {
    font-size: 125%;
}

.sg-icon-grid {
    font-size: 125%;
}

.sg-icon-gwinstalled {
    font-size: 125%;
}

.sg-icon-hseq {
    font-size: 125%;
}

.sg-icon-humanresources {
    font-size: 125%;
}

.sg-icon-internet {
    font-size: 125%;
}

.sg-icon-it {
    font-size: 125%;
}

.sg-icon-itapplication {
    font-size: 125%;
}

.sg-icon-lightbulb {
    font-size: 125%;
}

.sg-icon-manufacturing {
    font-size: 125%;
}

.sg-icon-money {
    font-size: 125%;
}

.sg-icon-nacelle {
    font-size: 125%;
}

.sg-icon-noise_emissions {
    font-size: 125%;
}

.sg-icon-nominal_power2 {
    font-size: 125%;
}

.sg-icon-pm {
    font-size: 125%;
}

.sg-icon-procurement {
    font-size: 125%;
}

.sg-icon-profit {
    font-size: 125%;
}

.sg-icon-quality {
    font-size: 125%;
}

.sg-icon-realtime {
    font-size: 125%;
}

.sg-icon-revenue {
    font-size: 125%;
}

.sg-icon-sales {
    font-size: 125%;
}

.sg-icon-scale {
    font-size: 125%;
}

.sg-icon-securefile {
    font-size: 125%;
}

.sg-icon-supplychain {
    font-size: 125%;
}

.sg-icon-sustainability {
    font-size: 125%;
}

.sg-icon-tower {
    font-size: 125%;
}

.sg-icon-training {
    font-size: 125%;
}

.sg-icon-appaccess {
    font-size: 125%;
}

.sg-icon-shareprice_down {
    font-size: 125%;
}

.sg-icon-shareprice_up {
    font-size: 125%;
}

.sg-icon-swipen_mobile {
    font-size: 125%;
}

.sg-icon-youtube {
    font-size: 125%;
}

.sg-icon-twitter {
    font-size: 125%;
}

.sg-icon-linkedin {
    font-size: 125%;
}

.sg-icon-instagram {
    font-size: 125%;
}

.sg-icon-facebook {
    font-size: 125%;
}

.sg-icon-windenergy {
    font-size: 125%;
}

.sg-icon-service {
    font-size: 125%;
}

.sg-icon-onshore {
    font-size: 125%;
}

.sg-icon-offshore {
    font-size: 125%;
}

.sg-icon-wind_class {
    font-size: 125%;
}

.sg-icon-technology {
    font-size: 125%;
}

.sg-icon-rotor_diameter {
    font-size: 125%;
}

.sg-icon-nominal_power {
    font-size: 125%;
}

.sg-icon-hub_high {
    font-size: 125%;
}

.sg-icon-available_certificate {
    font-size: 125%;
}

.sg-icon-telefonnummer {
    font-size: 125%;
}

.sg-icon-sorting {
    font-size: 125%;
}

.sg-icon-service_benefits {
    font-size: 125%;
}

.sg-icon-search {
    font-size: 125%;
}

.sg-icon-plus {
    font-size: 125%;
}

.sg-icon-play {
    font-size: 125%;
}

.sg-icon-pin {
    font-size: 125%;
}

.sg-icon-exclamationMark {
    font-size: 125%;
}

.sg-icon-download {
    font-size: 125%;
}

.sg-icon-close {
    font-size: 125%;
}

.sg-icon-check {
    font-size: 125%;
}

.sg-icon-calendar {
    font-size: 125%;
}

.sg-icon-burger {
    font-size: 125%;
}

.sg-icon-arrow {
    font-size: 100%;
    top: -1px;
}

.sg-icon-arrow_up_down {
    font-size: 125%;
}

.sg-icon-anschrift {
    font-size: 125%;
}
