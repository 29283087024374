@import '../../theme';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/custom-forms';

.form-control {
  margin-bottom: 0.25rem;
  color: var(--cx-color-text);
  &:focus {
    border-color: var(--cx-color-secondary);
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  @include forFeature('a11yImproveContrast') {
    border: 1px solid var(--cx-color-dark);

    &:focus {
      border-color: var(--cx-color-text);
      background-color: var(--cx-color-inverse);
    }

    &::placeholder {
      color: var(--cx-color-secondary);
    }

    &::-webkit-input-placeholder {
      color: var(--cx-color-secondary);
    }

    &:-ms-input-placeholder {
      color: var(--cx-color-secondary);
    }

    &::-ms-input-placeholder {
      color: var(--cx-color-secondary);
    }
  }

  @include cx-highContrastTheme {
    border-color: var(--cx-color-text);
    background-color: var(--cx-color-background);
    color: var(--cx-color-text);
    &:focus {
      background-color: var(--cx-color-background);
      color: var(--cx-color-text);
    }
  }
}

cx-form-errors {
  p {
    font-size: 14px;
    margin: 6px 0;
    padding-inline-start: 25px;
    position: relative;
    word-break: break-word;

    @include forFeature('a11yImproveContrast') {
      @include type('7');
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
    }

    &::before {
      content: '';
      background-color: var(--cx-color-danger);
      border-radius: 50%;
    }

    &::after {
      content: '!';
      // TODO: (CXSPA-7588) - Remove feature flag next major release
      @include forFeature('a11yFormErrorMuteIcon') {
        content: '!' / '';
      }
      color: var(--cx-color-inverse);
      font-weight: var(--cx-font-weight-bold);
      text-align: center;
      line-height: 20px;
    }

    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}

cx-file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  input[type='file'] {
    display: none;
  }

  button {
    width: 100%;
  }
}

input,
input[type='checkbox'],
input[type='radio'],
textarea {
  &.ng-invalid {
    &.ng-dirty,
    &.ng-touched {
      border-color: var(--cx-color-danger);
    }
  }
}

ng-select.ng-invalid {
  &.ng-dirty,
  &.ng-touched {
    .ng-select-container {
      border-color: var(--cx-color-danger);
    }
  }
}

input[type='checkbox'],
input[type='radio'] {
  height: 22px;
  width: 22px;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.42857;
  appearance: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-size: inherit;
  box-sizing: border-box;
  outline: none;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  margin-top: 0.3rem;
  margin-inline-start: -2.25rem;
}

label {
  display: block;
  .label-content {
    display: block;
    margin-bottom: $label-margin-bottom;
    &.bold {
      font-weight: var(--cx-font-weight-semi);
    }
  }

  abbr.required-asterisk {
    color: var(--cx-color-danger);
  }
}

.notes-content {
  margin-top: 1rem;
}

legend {
  margin-bottom: $label-margin-bottom;
}

.form-check {
  margin-bottom: 18px;
  padding-inline-start: 2.25rem;

  label {
    font-weight: var(--cx-font-weight-normal);
  }

  .form-check-label {
    display: inline-block;
    position: relative;
    top: 4px;
  }

  input + .form-check-label {
    color: var(--cx-color-text);

    a {
      color: var(--cx-color-text);
      text-decoration: underline;

      &:hover {
        color: var(--cx-color-primary);
      }

      @include media-breakpoint-down(sm) {
        display: inline;
      }
    }

    &::after {
      content: none;
    }
  }

  input:disabled + .form-check-label {
    color: var(--cx-color-light);
  }

  input[role='checkbox'],
  input[role='radio'] {
    display: inline-block;

    &:focus {
      box-shadow: 0px 0px 0px 3px var(--cx-color-light);
      border-color: var(--cx-color-secondary);
    }
  }

  input[type='checkbox'] {
    border-style: solid;
    border-radius: 5px;
    border-width: 2px;
    border-color: var(--cx-color-medium);

    @include forFeature('a11yImproveContrast') {
      border-color: var(--cx-color-text);
    }

    &:checked {
      font-style: normal;
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-primary);

      &::after {
        color: var(--cx-color-inverse);
        position: absolute;

        content: '';
        display: inline-block;
        height: 6px;
        width: 9px;
        top: 5px;
        left: 5px;
        border-width: 0 0 2px 2px;
        border-style: solid;
        border-color: var(--cx-color-inverse);
        transform: rotate(-45deg);
      }
    }
  }

  @include cx-highContrastTheme-dark {
    input[type='checkbox']:checked {
      border-color: var(--cx-color-dark);
      background-color: var(--cx-color-medium);
    }
  }
}

.form-control[type='text'] {
  &:focus {
    background-color: var(--cx-color-background);
    @include forFeature('a11yImproveContrast') {
      background-color: var(--cx-color-inverse);
    }
    box-shadow: none;
    -webkit-box-shadow: none;
    @include cx-highContrastTheme {
      background-color: var(--cx-color-background);
      color: var(--cx-color-text);
    }
  }
}

input[type='radio'] {
  border-radius: 50%;
  display: inline-block;
  border-style: solid;
  border-width: 2px;
  border-color: var(--cx-color-medium);
  position: absolute;

  @include forFeature('a11yImproveContrast') {
    border-color: var(--cx-color-text);
  }

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--cx-color-inverse);
    left: 4px;
    top: 4px;
  }
  &:checked {
    border-color: var(--cx-color-primary) !important;
    background-color: var(--cx-color-inverse);

    &::after {
      background-color: var(--cx-color-primary);
    }
  }
}

.form-message {
  @include type(7);
  &--danger {
    color: var(--cx-color-danger);
  }
}

.form-legend {
  font-style: italic;
  color: var(--cx-color-secondary);
  margin-bottom: 1rem;
}

.ng-select,
.ng-select.ng-select-single {
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option.ng-option-marked {
        background-color: var(--cx-color-light);
        @include forFeature('a11yImproveContrast') {
          outline: 2px solid var(--cx-color-visual-focus);
          margin-top: 2px;
          margin-bottom: 2px;
          margin-inline-start: 2px;
          margin-inline-end: 2px;
        }
        @include cx-highContrastTheme {
          background-color: var(--cx-color-background);
        }
      }
    }
  }

  .ng-arrow-wrapper {
    .ng-arrow {
      border-color: var(--cx-color-text) transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }

  .ng-select-container {
    padding: 0.6875rem 0;
    line-height: 1.6;
    height: unset;
    @include forFeature('a11yImproveContrast') {
      border: 1px solid var(--cx-color-dark);
    }
    @include cx-highContrastTheme {
      background-color: var(--cx-color-background);
      color: var(--cx-color-text);
      border-color: var(--cx-color-text);
    }

    .ng-value-container {
      padding-inline-start: 10px;
    }
  }

  @include cx-highContrastTheme {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      background-color: var(--cx-color-background);
      color: var(--cx-color-text);
    }
    s .ng-arrow-wrapper {
      .ng-arrow {
        border-color: var(--cx-color-text) transparent transparent;
      }
    }
  }
}

.ng-select {
  .ng-arrow-wrapper {
    padding-inline-end: 5px;
  }
  @include cx-highContrastTheme {
    background-color: var(--cx-color-background);
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      background-color: var(--cx-color-background);
      color: var(--cx-color-text);
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        border-color: var(--cx-color-text) transparent transparent;
      }
    }
  }
}

:focus:not(main) {
  @include visible-focus();
}

.form-control,
input {
  &:focus {
    @include visible-focus();
  }
}
.ng-select-focused {
  @include visible-focus();
}

.form-check {
  //crossbroswer input options without vendor prefixes

  //radio buttons
  input[type='radio'] {
    appearance: none;
  }
  input[type='radio']::after {
    visibility: hidden;
  }
  input[type='radio']:checked::after {
    visibility: visible;
  }

  //checkboxes
  input[type='checkbox'] {
    appearance: none;
  }
}

.form-check {
  //crossbrowser radio button
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input[type='radio']::after {
    visibility: hidden;
  }
  input[type='radio']:checked::after {
    visibility: visible;
  }

  //crossbrowser checkbox button
  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  @include cx-highContrastTheme {
    input[type='radio']:checked {
      border-color: var(--cx-color-dark) !important;
      background-color: var(--cx-color-light);
    }
    input[type='radio']:checked::after {
      background-color: var(--cx-color-dark);
    }
  }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--cx-color-secondary);
  @include cx-highContrastTheme {
    background-color: var(--cx-color-background);
    color: var(--cx-color-text);
  }
}

.form-group {
  input[type='text']::placeholder,
  input[type='tel']::placeholder {
    color: var(--cx-color-secondary);
    opacity: 1;

    @include forFeature('a11yImproveContrast') {
      color: var(--cx-color-secondary);
    }
  }

  input[type='text']:-ms-input-placeholder,
  input[type='tel']:-ms-input-placeholder {
    color: var(--cx-color-secondary);
  }

  input[type='text']::-ms-input-placeholder,
  input[type='tel']::-ms-input-placeholder {
    color: var(--cx-color-secondary);
  }
}
form {
  @include cx-highContrastTheme {
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition:
        background-color 0s 600000s,
        color 0s 600000s !important;
    }
  }
}

input[cxpasswordvisibilityswitch] {
  @include forFeature('a11yPasswordVisibliltyBtnValueOverflow') {
    $password-visibbility-btn-width: 30px;
    padding-inline-end: calc(
      $password-visibbility-btn-width + $input-padding-x
    );
  }
}
